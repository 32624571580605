import styled from 'styled-components';
import {
  mobile_width_percentage,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_breakpoint_extended,
  desktop_container_maxwidth_compact,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';
import chZIndex from '@components/zIndex';

export const InspirationStyles = styled.div`
  margin: 0 auto;
  position: relative;

  .area-info {
    margin-top: -20px;
  }
  .media-list {
    margin: 20px auto;
    width: ${mobile_width_percentage}%;
  }
  .no-media {
    text-align: center;

    h4 {
      color: ${Colors.accent.grey2.standard};
      margin-bottom: 20px;
    }
  }

  .zip-filter {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    .zip-current {
      align-items: center;
      color: ${Colors.primary.claytonBlue.standard};
      display: flex;
      flex-direction: row;
      font-weight: bold;
      margin: 0 3px;
      text-decoration: none;
    }
  }

  .inspire-filters {
    align-items: center;
    background-color: ${Colors.primary.white.standard};
    border-bottom: 1px solid ${Colors.accent.grey4.standard};
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    width: 100%;
    white-space: nowrap;

    &.sticky {
      position: sticky;
      top: 0;
      z-index: ${chZIndex.filterBars};
    }

    .auto-position .modal-window {
      left: 50%;
      margin-top: 1px;
      transform: translateX(-50%);

      .anchored-modal-content {
        padding-bottom: 10px;
        overflow-y: visible;

        .dropdown-menu {
          margin-top: 0;
          top: 42px;
        }
      }
    }
  }

  .category-filters {
    display: flex;
    flex-direction: row;

    .category {
      display: none;
    }
    .category,
    .dropdown-list button {
      border: solid 1px ${Colors.accent.grey4.standard};
      border-radius: 25px;
      color: ${Colors.primary.white.tint};
      font-weight: normal;
      margin: 5px;
      padding: 0 12px;
      text-transform: none;
    }

    .dropdown-selected-text {
      font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
    }
    .category.selected,
    .dropdown-list.has-category button {
      color: ${Colors.primary.white.standard};
      background-color: ${Colors.primary.claytonBlue.standard};
      border-color: ${Colors.primary.claytonBlue.standard};

      & ~ .dropdown-chevron svg * {
        fill: ${Colors.primary.white.standard};
      }
    }
    .dropdown-list {
      width: 200px;
    }
  }

  @media only screen and (min-width: ${tablet_container_maxwidth}px) {
    .inspire-filters {
      flex-direction: row;

      .auto-position .modal-window {
        left: 10px;
        transform: none;
      }
    }

    .page-overlay {
      top: 50px;
    }

    .modal-window {
      left: 0;
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .area-info {
      margin-top: 0;
      padding-top: 25px;
    }
    .category-filters .dropdown-list.open .dropdown-menu {
      .dropdown-display {
        margin: 5px auto;
        width: 80%;
      }
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint_extended}px) {
    .media-list {
      max-width: ${desktop_container_maxwidth_compact}px;
    }

    .inspire-filters {
      .category-filters .dropdown-list {
        display: none;
      }

      .category {
        display: block;
      }
    }
  }
`;

export default InspirationStyles;
