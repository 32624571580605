import React from 'react';
import PropTypes from 'prop-types';
import CTA from '../Shared/CTA/CTA';
import { getCtaProperties } from '@utils/ctaUtils';
import InspirationMediaCard from './InspirationMediaCard.js';
import { InspirationCardDeckStyles } from './InspirationCardDeck.styled.js';

const InspirationCardDeck = ({ mediaItems, onClick, deckIndex, cta }) => {
  const buildDisplay = () => {
    // eslint-disable-next-line prefer-destructuring
    const tourItem = mediaItems.filter(
      (item) => item.mediaType === 'Matterport 3D Tour'
    )[0];

    // if there's a tour item, move it to the begining of the array
    if (tourItem) {
      let tourItemIndex = mediaItems.indexOf(tourItem);
      mediaItems.splice(tourItemIndex, 1);
      mediaItems.splice(0, 0, tourItem);
    }

    const isOdd = (deckIndex + 1) % 2 == 1;

    // even
    if (mediaItems.length === 5) {
      if (isOdd) {
        return (
          <>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
            </div>
            <div className="quad">
              {mediaItems.slice(1, 5).map((media, index) => (
                <InspirationMediaCard
                  onClick={onClick}
                  key={'deck-' + deckIndex + '-card' + index}
                  media={media}
                />
              ))}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="quad">
              {mediaItems.slice(0, 4).map((media, index) => (
                <InspirationMediaCard
                  onClick={onClick}
                  key={'deck-' + deckIndex + '-card' + index}
                  media={media}
                />
              ))}
            </div>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[4]} />
            </div>
          </>
        );
      }
    } else if (mediaItems.length > 3) {
      if (isOdd) {
        return (
          <>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
            </div>
            <div className="quad">
              {mediaItems.slice(1, mediaItems.length).map((media, index) => (
                <InspirationMediaCard
                  onClick={onClick}
                  key={'deck-' + deckIndex + '-card' + index}
                  media={media}
                />
              ))}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="quad">
              {mediaItems
                .slice(0, mediaItems.length - 1)
                .map((media, index) => (
                  <InspirationMediaCard
                    onClick={onClick}
                    key={'deck-' + deckIndex + '-card' + index}
                    media={media}
                  />
                ))}
            </div>
            <div className="single">
              <InspirationMediaCard
                onClick={onClick}
                media={mediaItems[mediaItems.length - 1]}
              />
            </div>
          </>
        );
      }
    } else if (mediaItems.length === 3) {
      if (isOdd) {
        return (
          <>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
            </div>
            <div className="quad">
              {cta && (
                <CTA {...getCtaProperties(cta)} buttonOpensToNewTab={false} />
              )}
              {mediaItems.slice(1, 3).map((media, index) => (
                <InspirationMediaCard
                  onClick={onClick}
                  key={'deck-' + deckIndex + '-card-' + index}
                  media={media}
                />
              ))}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="quad">
              {mediaItems.slice(0, 2).map((media, index) => (
                <InspirationMediaCard
                  onClick={onClick}
                  key={'deck-' + deckIndex + '-card-' + index}
                  media={media}
                />
              ))}
              {cta && (
                <CTA {...getCtaProperties(cta)} buttonOpensToNewTab={false} />
              )}
            </div>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[2]} />
            </div>
          </>
        );
      }
    } else if (mediaItems.length === 2) {
      if (cta) {
        if (isOdd) {
          return (
            <>
              <div className="single">
                <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
              </div>
              <div className="quad">
                {cta && (
                  <CTA {...getCtaProperties(cta)} buttonOpensToNewTab={false} />
                )}
                {mediaItems.slice(1, 2).map((media, index) => (
                  <InspirationMediaCard
                    onClick={onClick}
                    key={'deck-' + deckIndex + '-card-' + index}
                    media={media}
                  />
                ))}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="quad">
                {mediaItems.slice(0, 1).map((media, index) => (
                  <InspirationMediaCard
                    onClick={onClick}
                    key={'deck-' + deckIndex + '-card-' + index}
                    media={media}
                  />
                ))}
                {cta && (
                  <CTA {...getCtaProperties(cta)} buttonOpensToNewTab={false} />
                )}
              </div>
              <div className="single">
                <InspirationMediaCard onClick={onClick} media={mediaItems[1]} />
              </div>
            </>
          );
        }
      } else {
        return (
          <>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
            </div>
            <div className="single">
              <InspirationMediaCard onClick={onClick} media={mediaItems[1]} />
            </div>
          </>
        );
      }
    } else if (mediaItems.length == 1) {
      if (cta) {
        if (isOdd) {
          return (
            <>
              <div className="single">
                <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
              </div>
              <div className="quad">
                {cta && (
                  <CTA {...getCtaProperties(cta)} buttonOpensToNewTab={false} />
                )}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="quad">
                {cta && (
                  <CTA {...getCtaProperties(cta)} buttonOpensToNewTab={false} />
                )}
              </div>
              <div className="single">
                <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
              </div>
            </>
          );
        }
      } else {
        return (
          <div className="single">
            <InspirationMediaCard onClick={onClick} media={mediaItems[0]} />
          </div>
        );
      }
    }
  };

  return (
    <InspirationCardDeckStyles
      className={
        'inspire-media-deck ' + ((deckIndex + 1) % 2 == 0 ? 'even' : 'odd')
      }
    >
      {buildDisplay()}
    </InspirationCardDeckStyles>
  );
};

InspirationCardDeck.propTypes = {
  cta: PropTypes.any,
  deckIndex: PropTypes.number,
  mediaItems: PropTypes.array,
  onClick: PropTypes.any,
};

export default InspirationCardDeck;
