import xHttp from '@utils/xHttp';
import { getBaseAwsApiUrl, getInspiredApiVersion } from '@utils/config';

export const getInspiredContentfulMedia = (zipCode) => {
  let url = `${getBaseAwsApiUrl()}${getInspiredApiVersion()}/inspired/media`;
  if (zipCode) {
    url += '?zipCode=' + zipCode;
  }
  return xHttp.get(url);
};

export const getInspiredContentfulMediaById = (contentfulId) => {
  return xHttp.get(
    `${getBaseAwsApiUrl()}${getInspiredApiVersion()}/inspired/media/${contentfulId}`
  );
};

// This End Point is not being used, we have replaced it with Dynamic Page Content.
// export const getInspiredContentfulPageInfo = () => {
//   return xHttp.get(`${getBaseAwsApiUrl()}v1/inspired/pageinfo`);
// };
