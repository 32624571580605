import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

export const InspirationMediaShareStyles = styled.div`
  align-items: center;
  background-color: ${Colors.primary.white.standard};
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 90vw;
  padding: 30px;

  h3 {
    margin-bottom: 10px;
  }

  .social-icons {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 200px;

    svg {
      height: 50px;
      width: 50px;

      * {
        fill: ${Colors.primary.claytonBlue.standard};
      }
    }
  }
`;
