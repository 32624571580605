import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

export const SwitchStyles = styled.div`
  align-items: center;
  box-sizing: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  .switch-container {
    margin-right: 10px;
    width: 40px;
  }

  input {
    display: none;
  }

  .switch-peg {
    background-color: ${Colors.accent.grey2.standard};
    border: 2px solid ${Colors.accent.grey5.standard};
    border-radius: 20px;
    cursor: pointer;
    display: block;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: background-color 0.1s ease-in;

    &:before {
      bottom: 0;
      content: '';
      display: block;
      background: ${Colors.accent.grey7.standard};
      border: 2px solid ${Colors.accent.grey5.standard};
      border-radius: 20px;
      margin: 0px;
      position: absolute;
      right: 16px;
      top: 0;
      transition: all 0.3s ease-in 0s;
      width: 16px;
    }

    &:hover:before {
      background-color: ${Colors.primary.white.standard};
    }
  }

  input:checked + .switch-peg {
    background-color: ${Colors.primary.claytonBlue.standard};
  }
  input:checked + .switch-peg,
  .switch-peg:checked + .switch-peg:before {
    border-color: ${Colors.primary.claytonBlue.standard};
  }
  input:checked + .switch-peg:before {
    background-color: ${Colors.primary.white.standard};
    right: 0px;
  }
`;
