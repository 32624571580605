import styled from 'styled-components';
import {
  mobile_width_percentage,
  tablet_breakpoint,
  desktop_breakpoint,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const AreaHeaderStyles = styled.div`
  background-color: ${Colors.primary.claytonBlue.standard};
  color: #fff;
  padding-top: 15px;

  .area-hero {
    margin: 0 auto;
    width: ${mobile_width_percentage}%;

    .breadcrumb .caption,
    .hero-heading,
    .hero-intro-text,
    .breadcrumb a:link,
    .breadcrumb a:visited,
    .breadcrumb a:hover,
    .breadcrumb a:active {
      color: #fff;
      padding-top: 2px;
    }
    .breadcrumb {
      margin-bottom: 30px;
      display: flex;

      .breadcrumb-chevron {
        margin-top: 7px;
        margin-left: 6px;
        stroke: #fff;
      }
    }
    .area-info {
      .hero-heading,
      .hero-intro-text {
        text-align: center;
      }
      .hero-heading {
        margin-bottom: 20px;
      }
      .hero-intro-text {
        padding-bottom: 30px;
      }
    }
  }
  @media only screen and (min-width: ${tablet_breakpoint}px) {
    background-image: ${(props) =>
      props.bgImage ? `url(${props.bgImage})` : 'none'};
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .area-hero {
      .breadcrumb {
        margin: 0 auto;
      }
      .area-info {
        margin: 0 auto;
        max-width: ${desktop_container_maxwidth}px;

        .hero-intro-text {
          padding-bottom: 60px;
        }
      }
    }
  }
`;
