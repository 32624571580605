import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import ChevronRight from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import { AreaHeaderStyles } from './AreaHeader.styled';

const AreaHeader = ({
  headerText,
  infoText,
  breadCrumbs,
  bgImage,
  children,
}) => {
  const makeCrumb = (crumb, index) => {
    if (crumb.link) {
      return (
        <Link
          key={'breadcrumb-link-' + index}
          href={crumb.link}
          className="caption"
        >
          {crumb.text}
        </Link>
      );
    }

    return (
      <span key="breadcrumb-text" className="caption">
        {crumb.text}
      </span>
    );
  };
  return (
    <AreaHeaderStyles className="area-header" bgImage={bgImage}>
      <div className="area-hero">
        <div className="breadcrumb">
          {breadCrumbs &&
            breadCrumbs.map((crumb, index) => {
              if (index !== 0) {
                return (
                  <React.Fragment key={'crumb-' + index}>
                    <div
                      key={'breadcrumb-divider-' + index}
                      className="breadcrumb-chevron"
                    >
                      <ChevronRight />
                    </div>

                    {makeCrumb(crumb)}
                  </React.Fragment>
                );
              }

              return makeCrumb(crumb);
            })}
        </div>
        <div className="area-info">
          {headerText && <h1 className="hero-heading">{headerText}</h1>}
          {infoText && <p className="hero-intro-text">{infoText}</p>}
          {children}
        </div>
      </div>
    </AreaHeaderStyles>
  );
};

AreaHeader.propTypes = {
  bgImage: PropTypes.any,
  breadCrumbs: PropTypes.array,
  children: PropTypes.any,
  headerText: PropTypes.any,
  infoText: PropTypes.any,
};

export default AreaHeader;
