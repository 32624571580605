import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeUrl } from 'pubweb-smokey/dist/utils/utils';
import { getSiteUrl } from '@utils/config';
import FacebookSvg from 'pubweb-smokey/dist/images/svg/iconography-social/facebookSingleLayer.svg';
import PinterestSvg from 'pubweb-smokey/dist/images/svg/iconography-social/pinterest.svg';
import TwitterSvg from 'pubweb-smokey/dist/images/svg/iconography-social/twitter.svg';
import { InspirationMediaShareStyles } from './InspirationMediaShare.styled';
import { formatHttpsUrl } from '@utils/utils';

const InspirationMediaShare = ({ media }) => {
  const shareText = media.description;
  const shareUrl = sanitizeUrl(
    getSiteUrl() +
      '/get-inspired/' +
      media.category?.toLowerCase() +
      '/' +
      media.modelNumber +
      '/' +
      media.contentfulResourceId +
      '/'
  );
  const shareImageUrl = formatHttpsUrl(media.mediaUrl);

  const makeShareIcon = (socialClient) => {
    switch (socialClient) {
      case 'facebook':
        return (
          <a
            href={`https://facebook.com/sharer.php?u=${encodeURI(shareUrl)}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FacebookSvg />
          </a>
        );
      case 'twitter':
        return (
          <a
            href={`https://twitter.com/intent/tweet?url=${encodeURI(
              shareUrl
            )}&text=${encodeURI(shareText)}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <TwitterSvg viewBox="0 0 16 16" />
          </a>
        );
      case 'pinterest':
        return (
          <a
            href={`https://www.pinterest.com/pin/create/button/?url=${encodeURI(
              shareUrl
            )}&media=${encodeURI(shareImageUrl)}&description=${encodeURI(
              shareText
            )}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <PinterestSvg viewBox="0 0 16 16" />
          </a>
        );
    }

    return 'Error';
  };

  return (
    <InspirationMediaShareStyles>
      <h3>Share</h3>
      <div className="social-icons">
        {['facebook', 'twitter', 'pinterest'].map((socialClient) =>
          makeShareIcon(socialClient)
        )}
      </div>
    </InspirationMediaShareStyles>
  );
};

InspirationMediaShare.propTypes = {
  media: PropTypes.shape({
    category: PropTypes.string,
    contentfulResourceId: PropTypes.any,
    description: PropTypes.any,
    mediaUrl: PropTypes.any,
    modelNumber: PropTypes.any,
  }),
};

export default InspirationMediaShare;
