
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/get-inspired/[[...category]]",
      function () {
        return require("private-next-pages/get-inspired/[[...category]].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/get-inspired/[[...category]]"])
      });
    }
  