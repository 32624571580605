import styled from 'styled-components';
import {
  tablet_breakpoint,
  desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const gridGap = 10;
export const InspirationCardDeckStyles = styled.div`
  .inspire-media-image a {
    display: block;
    margin: auto;
    overflow: hidden;
  }

  .single,
  .quad {
    .inspire-media-image {
      margin-bottom: ${(gridGap - 2) * 2}px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .quad {
    display: flex;
    flex-direction: column;

    .inspire-media-image:nth-child(1) {
      order: 1;
    }

    .cta {
      order: 2;
    }

    .inspire-media-image:nth-child(2) {
      order: 3;
    }
  }

  .cta {
    background-size: cover;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    margin: auto;
    margin-bottom: ${gridGap * 2}px;
    width: 100%;

    &:last-child:not(:first-child),
    & ~ .inspire-media-image {
      margin-top: auto;
      order: 2;
    }
    .cta-inside {
      height: auto;
      padding: 10px;
    }
    .cta-heading {
      /* Make heading text white because one of the cards was displaying black text */
      color: #fff;
      margin-left: 0;
      max-width: none;
      width: 80%;
    }
    .cta-button {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: ${tablet_breakpoint}px) and (max-width: 900px) {
    .cta {
      .cta-heading {
        font-size: 16px;
      }

      .cta-inside {
        padding: 5px;
      }

      .cta-button {
        font-size: 12px;
        padding: 10px;
      }
    }
  }

  @media only screen and (max-width: 550px) {
    .cta {
      background-image: none;
      height: 170px;
      .cta-heading {
        font-size: 18px;
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: ${desktop_breakpoint}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${gridGap * 2}px;

    .inspire-media-image a {
      margin: 0;
    }

    .single,
    .quad {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(50% - ${gridGap}px);

      .inspire-media-image:nth-child(1),
      .inspire-media-image:nth-child(2),
      .cta {
        order: initial;
      }
    }

    .single {
      .inspire-media-image {
        width: 100%;
        max-height: 540px;
      }
    }

    .quad {
      .inspire-media-image {
        width: calc(50% - ${gridGap}px);
        max-height: 260px;

        &:nth-child(3),
        &:nth-child(4) {
          margin-top: auto;
        }
      }
    }

    .cta {
      height: calc(50% - ${gridGap * 2}px);
      margin: 0;
      width: 100%;

      & ~ .inspire-media-image {
        margin-top: auto;
      }
    }

    .quad.cta-only {
      .cta {
        height: 100%;
      }
    }
  }
`;
