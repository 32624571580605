import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SwitchStyles } from './Switch.styled';

const Switch = React.forwardRef(function Switch(
  {
    label,
    id = '',
    disabled = false,
    switchStyle = 'default',
    automationId,
    onChange,
    className,
    children = null,
    errorMessage,
    ...props
  },
  ref
) {
  const propertyClasses = [];

  useEffect(() => {
    if (!automationId) {
      console.warn('Automation id was not provided.');
    }
  }, [automationId]);

  if (switchStyle.toLowerCase().trim() === 'compact') {
    propertyClasses.push('compact');
  }

  if (errorMessage) {
    propertyClasses.push('error');
  }

  if (disabled) {
    propertyClasses.push('disabled');
  }

  const switchRef = useRef();

  const handleKeyDown = (e) => {
    // spacebar
    if (e.target === switchRef.current && e.keyCode === 32 && !disabled) {
      checkBoxRef.current.click();
      checkBoxRef.current.checked = !checkBoxRef.current.checked;
      e.preventDefault();
    }
  };
  const inputEl = useRef(null);
  const checkBoxRef = ref || inputEl;

  const handleClick = (e) => {
    if (checkBoxRef.current) {
      checkBoxRef.current.click();
      checkBoxRef.current.checked = !checkBoxRef.current.checked;
    }
    e.preventDefault();
  };

  return (
    <SwitchStyles
      className={className + ' ' + propertyClasses.join(' ')}
      tabIndex="0"
      ref={switchRef}
      onKeyDown={handleKeyDown}
      $errorMessage={errorMessage}
    >
      {switchStyle === 'label-left' && (
        <label htmlFor={id} onClick={handleClick}>
          {label || children}
        </label>
      )}
      <div
        className="switch-container"
        onClick={handleClick}
        aria-label={label}
        role="checkbox"
      >
        <input
          id={id}
          data-test-id={automationId}
          type="checkbox"
          disabled={disabled}
          ref={checkBoxRef}
          onClick={onChange}
          {...props}
        />
        <span className="switch-peg"></span>
      </div>
      {switchStyle !== 'label-left' && (
        <label htmlFor={id} onClick={handleClick}>
          {label || children}
        </label>
      )}
      {errorMessage ? (
        <span className="error-message">{errorMessage}</span>
      ) : null}
    </SwitchStyles>
  );
});

export default Switch;

Switch.defaultProps = {
  label: '',
  id: '',
  disabled: false,
  automationId: '',
  className: '',
  switchStyle: 'default',
  errorMessage: '',
};

Switch.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  automationId: PropTypes.string,
  className: PropTypes.string,
  switchStyle: PropTypes.oneOf(['', 'default', 'label-left', 'label-right']),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
