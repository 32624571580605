import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { InspirationMediaCardStyles } from './InspirationMediaCard.styled.js';
import Modal from '@components/Shared/Modal/Modal';
import TourSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/cube-open.svg';
import ShareSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/share-branch.svg';
import FavoritingHeart from '@components/Shared/Favoriting/FavoritingHeart';
import { DXFavoritesContext } from '@contexts/DXFavoritesContext';
import InspirationMediaShare from './InspirationMediaShare';
import { formatHttpsUrl } from '@utils/utils';

const InspirationMediaCard = ({ media, onClick }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const favoritesContext = useContext(DXFavoritesContext);
  return (
    <InspirationMediaCardStyles className="inspire-media-image">
      <Link
        href={`/get-inspired/${media.category}/${media.modelNumber}/${media.contentfulResourceId}/`}
        onClick={onClick}
      >
        <Image
          alt={media.altText}
          src={formatHttpsUrl(`${media.mediaUrl}?w=660&fm=webp`)}
          width={660}
          height={660}
        />
        {media.matterportId && (
          <span tabIndex="0" className="tour label large">
            <TourSvg /> VIEW 3D TOUR
          </span>
        )}
      </Link>
      <span
        tabIndex="0"
        className="share"
        onClick={() => setShowShareModal(true)}
      >
        <ShareSvg />
      </span>
      <span className="favorite">
        <FavoritingHeart
          tabIndex="0"
          isFavorited={
            !!favoritesContext.state.favoriteModels?.filter(
              (faveModels) => faveModels.modelNumber === media.modelNumber
            )[0]
          }
          onClick={() =>
            favoritesContext.actions.toggleFavoriteModel(media.modelNumber)
          }
        />
      </span>
      {showShareModal && (
        <Modal onClose={() => setShowShareModal(false)}>
          <InspirationMediaShare media={media} />
        </Modal>
      )}
    </InspirationMediaCardStyles>
  );
};

InspirationMediaCard.propTypes = {
  media: PropTypes.shape({
    altText: PropTypes.any,
    category: PropTypes.any,
    contentfulResourceId: PropTypes.any,
    matterportId: PropTypes.any,
    mediaUrl: PropTypes.any,
    modelId: PropTypes.any,
    modelNumber: PropTypes.any,
  }),
  onClick: PropTypes.any,
};

export default InspirationMediaCard;
