import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import ScrollUpButtonContainer from '@components/Shared/ScrollUpButton/ScrollUpButtonContainer';
import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import AreaHeader from '@components/Shared/AreaHeader/AreaHeader';
import { PageContext } from '@contexts/PageContext';
import { LocationsContext } from '@contexts/LocationsContext';
import { getDynamicPageContent } from '@services/contentfulService';
import { getSiteUrl } from '@utils/config';
import {
  getInspiredContentfulMedia,
  getInspiredContentfulMediaById,
} from '@services/inspirationService';
import Colors from 'pubweb-smokey/dist/colors';
import Button from '@components/Shared/Buttons/Button';
import DropDownList from '@components/Shared/DropDownList/DropDownList';
import ChevronDwnSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import LoadingIndicator from '@components/Shared/LoadingIndicator/LoadingIndicator';
import Switch from '@components/Shared/Switch/Switch';
import { runCommonValidation } from 'pubweb-smokey/dist/utils/validation';
import { getQueryParams, isMobile } from 'pubweb-smokey/dist/utils/utils';

import InspirationCardDeck from '@components/Inspiration/InspirationCardDeck';
import { InspirationStyles } from './Inspiration.styled';
import { categories } from '@utils/inspirationCategories';

const Modal = dynamic(() => import('@components/Shared/Modal/Modal'), {
  ssr: true,
});

const InspirationMediaView = dynamic(
  () => import('@components/Inspiration/InspirationMediaView'),
  { ssr: true }
);

const AnchoredModal = dynamic(
  () => import('@components/Shared/Modal/AnchoredModal/AnchoredModal'),
  { ssr: false }
);

const Form = dynamic(() => import('@components/Shared/Form/Form'), {
  ssr: false,
});

const CityLocationSuggestion = dynamic(
  () => import('@components/Shared/CityLocationList/CityLocationSuggestion'),
  {
    ssr: false,
  }
);

const LOAD_MORE_DESKTOP = 4;
const LOAD_MORE_MOBILE = 2;
const CTA_START_DESKTOP = 2;
const CTA_START_MOBILE = 1;
const CTA_FREQ = 3;

const Inspiration = ({ ssrInspireDpc, ssrMediaViewImage, ssrMediaView }) => {
  const pageContext = useContext(PageContext);
  const locationsContext = useContext(LocationsContext);
  const mediaDecksPerLoad = isMobile(desktop_breakpoint)
    ? LOAD_MORE_MOBILE
    : LOAD_MORE_DESKTOP;

  const [inspireDpc, setInspireDpc] = useState(ssrInspireDpc);
  const [mediaImages, setMediaImages] = useState(ssrMediaViewImage);
  const [mediaDecks, setMediaDecks] = useState([]);
  const [stickyFilter, setStickyFilter] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const [loading, setLoading] = useState(!ssrMediaViewImage);
  const router = useRouter();
  const [pCategory, , pMediaId] = router.query.category || [];
  const [showViewModal, setShowViewModal] = useState(pMediaId || null);
  const [showZipModal, setShowZipModal] = useState(false);
  const [mediaView, setMediaView] = useState(null);

  const [zipCode, setZipCode] = useState();
  const [zipFilter, setZipFilter] = useState(locationsContext.state.postalCode);
  const [filterByZip, setFilterByZip] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [mediaDisplayCount, setMediaDisplayCount] = useState(mediaDecksPerLoad);
  const [category, setCategory] = useState((pCategory || '').toLowerCase());

  const findMedia = (zipCode) => {
    setLoading(true);
    getInspiredContentfulMedia(zipCode).then((result) => {
      setMediaImages(getSortedMediaResults(result));
      setLoading(false);
      setMediaDisplayCount(mediaDecksPerLoad);
    });
  };

  const getTitle = () => {
    return inspireDpc?.titleTag;
  };

  const getDescription = () => {
    return inspireDpc?.metaDescription;
  };

  const getSortedMediaResults = (result) => {
    let featuredHomes = [];
    let nonFeaturedHomes = [];
    let sortedMediaResults = [];

    if (result && result.media && result.media.length > 0) {
      result.media.forEach((element) => {
        if (element.mediaDate && element.mediaDate.trim() === '') {
          element.mediaDate = null;
        }

        if (element.featuredHome) {
          featuredHomes.push(element);
        } else {
          nonFeaturedHomes.push(element);
        }
      });

      featuredHomes.sort(function (a, b) {
        return new Date(b.mediaDate) - new Date(a.mediaDate);
      });

      nonFeaturedHomes.sort(function (a, b) {
        return new Date(b.mediaDate) - new Date(a.mediaDate);
      });

      sortedMediaResults = sortedMediaResults
        .concat(featuredHomes)
        .concat(nonFeaturedHomes);
    }

    return {
      hasResults: result.hasResults,
      media: sortedMediaResults,
    };
  };

  // this is done to overcome an issue with the drop down being instead of a sticky bar
  // that causes its modal to appear under the header
  const makeFilterSticky = () => {
    if (window.scrollY > 150) {
      setStickyFilter(true);
    } else {
      setStickyFilter(false);
    }
  };

  useEffect(() => {
    if (!inspireDpc) {
      getDynamicPageContent(getDpcKey(category)).then((result) =>
        setInspireDpc(result)
      );
    }

    window.addEventListener('scroll', makeFilterSticky);

    if (category) {
      if (categories.filter((c) => category === c.value).length === 0) {
        router.replace(`/get-inspired/`);
      }
    }

    return () => {
      window.removeEventListener('scroll', makeFilterSticky);
    };
  }, []);

  useEffect(() => {
    if (ssrMediaView) {
      if (!showViewModal) {
        setShowViewModal(true);
      }
      setMediaView(ssrMediaView);
    } else {
      setShowViewModal(false);
      setMediaView(null);
    }
  }, [ssrMediaView]);

  useEffect(() => {
    if (pCategory && !pMediaId) {
      setCategory(pCategory);
    } else if (!router.query.category) {
      setCategory('');
    }
  }, [router.query]);

  useEffect(() => {
    findMedia(filterByZip ? zipFilter : '');
  }, [zipFilter, filterByZip]);

  useEffect(() => {
    setMediaDisplayCount(mediaDecksPerLoad);
    setMediaDecks(buildDecks());
  }, [mediaImages, inspireDpc]);

  useEffect(() => {
    setZipCode(locationsContext.state.postalCode);
    if (!zipFilter) {
      setZipFilter(locationsContext.state.postalCode);
    }
  }, [locationsContext]);

  useEffect(() => {
    if (!firstRender) {
      getDynamicPageContent(getDpcKey(category)).then((result) => {
        setInspireDpc(result);
      });
    } else {
      setFirstRender(false);
    }
  }, [category]);

  const buildDecks = () => {
    if (!mediaImages || !mediaImages.media || !inspireDpc) {
      return [];
    }

    const groupedDecks = [];
    let mediaIndex = 0;

    const ctaFrequency = CTA_FREQ;
    let ctaCounter = 0;
    const ctaStart = isMobile(desktop_breakpoint)
      ? CTA_START_MOBILE
      : CTA_START_DESKTOP;

    // I will change this let to const after testing
    let filteredMediaImages = mediaImages.media.filter(
      (media) =>
        media.category.toLowerCase() ===
        (category || media.category.toLowerCase())
    );

    if (getQueryParams().count) {
      filteredMediaImages = filteredMediaImages.slice(
        0,
        parseInt(getQueryParams().count)
      );
    }

    while (mediaIndex < filteredMediaImages.length) {
      let hasCta = groupedDecks.length % ctaFrequency === ctaStart;
      const mediaCount =
        category === '3d-tours' ? (hasCta ? 3 : 2) : hasCta ? 3 : 5;

      groupedDecks.push({
        media: filteredMediaImages.slice(mediaIndex, mediaIndex + mediaCount),
        cta: hasCta ? inspireDpc.contentCTAs[ctaCounter++] : null,
      });

      if (ctaCounter >= inspireDpc.contentCTAs.length) {
        ctaCounter = 0;
      }

      mediaIndex += mediaCount;
    }

    // if we've displayed everything...
    if (groupedDecks.length > 0) {
      // check to see if the last group has a cta
      let ctaCheckIndex =
        parseInt((groupedDecks.length - 1) / mediaDecksPerLoad) *
        mediaDecksPerLoad;
      let lastGroupHasCta = false;

      while (ctaCheckIndex < groupedDecks.length) {
        if (groupedDecks[ctaCheckIndex++].cta) {
          lastGroupHasCta = true;
        }
      }

      if (!lastGroupHasCta) {
        const lastGroupDeck = groupedDecks[groupedDecks.length - 1];

        // last group doesn't have a CTA, so let's add 1!
        if (lastGroupDeck.media.length >= 3) {
          lastGroupDeck.cta = inspireDpc.contentCTAs[ctaCounter];
          groupedDecks.push({
            media: lastGroupDeck.media.splice(3, 2),
          });
        } else {
          lastGroupDeck.cta = inspireDpc.contentCTAs[ctaCounter];
        }
      }
    }

    return groupedDecks;
  };

  const applyZip = (newZip) => {
    var formErrors = runCommonValidation({
      zip: {
        touched: true,
        value: newZip,
      },
    });
    setFormErrors(formErrors);
    if (!formErrors.hasErrors) {
      setZipFilter(newZip);
      setFilterByZip(true);
      setShowZipModal(false);
      locationsContext.actions.updatePostalCode(newZip);
    }
  };

  const showLoadMore = () => {
    if (!loading && mediaDecks && mediaDecks.length > mediaDisplayCount) {
      // if we only have one deck left to display and that deck doesn't contain any media, then don't display
      // the LOAD MORE button
      if (mediaDecks.length - mediaDisplayCount == 1) {
        if (mediaDecks[mediaDecks.length - 1].media.length == 0) {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  return (
    <>
      <Head htmlAttributes={{ lang: 'en' }}>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <link rel="canonical" href={`${getSiteUrl(router.asPath)}`} />

        {mediaView
          ? [
              <meta property="og:description" content="My dream home 😍😍" />, // eslint-disable-line react/jsx-key
              <meta property="og:type" content="website" />, // eslint-disable-line react/jsx-key
              // eslint-disable-next-line react/jsx-key
              <meta
                property="og:image"
                content={'https:' + mediaView.mediaUrl}
              />,
              // eslint-disable-next-line react/jsx-key
              <meta
                name="twitter:image"
                content={'https:' + mediaView.mediaUrl}
              />,
            ]
          : [
              // eslint-disable-next-line react/jsx-key
              <meta
                property="og:image"
                content={
                  typeof inspireDpc?.ogImage !== 'undefined' &&
                  inspireDpc?.ogImage !== null
                    ? inspireDpc?.ogImage?.file?.url
                    : `${getSiteUrl('/public-assets/images/clayton-logo.jpg')}`
                }
              />,
            ]}
      </Head>
      <ScrollUpButtonContainer />
      <InspirationStyles>
        <AreaHeader
          headerText={inspireDpc?.pageTitle}
          infoText={inspireDpc?.heroText}
        />
        <div className={`inspire-filters ${stickyFilter ? 'sticky' : ''}`}>
          <div className="zip-filter">
            <Switch
              className="gtm-location-toggle"
              id="zip-active"
              checked={filterByZip}
              onChange={(e) => {
                setFilterByZip(e.target.checked);
                setShowZipModal(false);
                window.scrollTo(0, 0);
              }}
              label="Only show homes near"
            />
            <a
              href="#"
              className="zip-current standard"
              onClick={(e) => {
                e.preventDefault();
                pageContext.state.scrollDirection = 'down'; //hide mobileNavBar
                setShowZipModal(!showZipModal);
              }}
            >
              {zipFilter}
              <ChevronDwnSvg />
            </a>
            {showZipModal && (
              <AnchoredModal
                autoPosition={true}
                showClearButton={false}
                applyButtonClassName="gtm-zipcode-submit"
                onApplyButtonClick={() => {
                  applyZip(zipCode);
                  window.scrollTo(0, 0);
                }}
                onCloseClick={() => setShowZipModal(false)}
              >
                <Form
                  onSubmit={() => {
                    applyZip(zipCode);
                    window.scrollTo(0, 0);
                  }}
                >
                  <CityLocationSuggestion
                    dropDownStyle="compact"
                    id="zip"
                    errorMessage={formErrors.zip}
                    onChange={(e) => setZipCode(e)}
                  />
                </Form>
              </AnchoredModal>
            )}
          </div>
          <div className="category-filters">
            {categories.map((categoryFilter) => {
              const effectiveFilter =
                categoryFilter.value === category ? '' : categoryFilter.value;

              return (
                // eslint-disable-next-line react/jsx-key
                <Button
                  className={
                    'category standard ' +
                    (categoryFilter.value === category && 'selected')
                  }
                  buttonStyle="outlined"
                  onClick={() => window.scrollTo(0, 0)}
                  nextJSUrl={`/get-inspired${
                    effectiveFilter && '/' + effectiveFilter
                  }/`}
                  linkComponent={Link}
                  primaryColor={Colors.primary.black}
                >
                  {categoryFilter.text}
                </Button>
              );
            })}
            <DropDownList
              dropDownStyle="compact"
              label="Category"
              items={[{ text: 'All Categories', value: '' }, ...categories]}
              selectedValue={category}
              className={category ? 'has-category' : ''}
              onChange={(selectedCategory) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                router.push(
                  `/get-inspired${
                    selectedCategory.value ? '/' + selectedCategory.value : ''
                  }/`
                );
              }}
            />
          </div>
        </div>
        <div className="media-list">
          {loading && <LoadingIndicator width="50" height="50" />}
          {mediaImages && !mediaImages.hasResults && (
            <div className="no-media">
              <h4>No results found in your area.</h4>
            </div>
          )}
          {!loading &&
            mediaImages &&
            !mediaImages.hasResults &&
            mediaDecks.length > 0 &&
            !category && (
              <div className="no-media">
                <h4>Find Inspiration with these home details!</h4>
              </div>
            )}
          {!loading && category && mediaDecks.length === 0 && (
            <div className="no-media">
              <h4>No results found for this category.</h4>
            </div>
          )}
          {!loading &&
            mediaDecks &&
            mediaDecks.slice(0, mediaDisplayCount).map((deck, index) => {
              return (
                <InspirationCardDeck
                  key={'deck-' + index}
                  mediaItems={deck.media}
                  cta={deck.cta}
                  deckIndex={index}
                  onClick={() => {
                    setShowViewModal(true);
                  }}
                />
              );
            })}
          {showLoadMore() && (
            <Button
              className="gtm-inspired-load-homes"
              fillContainer={true}
              buttonStyle="outlined"
              onClick={(e) => {
                e.target.blur();
                setMediaDisplayCount(mediaDisplayCount + mediaDecksPerLoad);
              }}
            >
              Load More
            </Button>
          )}
        </div>
        {showViewModal && (
          <Modal
            onClose={(e) => {
              if (!e.defaultPrevented) {
                router.push(
                  `/get-inspired${
                    category ? '/' + category.toLowerCase() : ''
                  }/`
                );
                setMediaView(null);
                setShowViewModal(false);
              }
            }}
          >
            <InspirationMediaView media={mediaView} />
          </Modal>
        )}
      </InspirationStyles>
    </>
  );
};

Inspiration.propTypes = {
  ssrInspireDpc: PropTypes.any,
  ssrMediaViewImage: PropTypes.any,
  ssrMediaView: PropTypes.object,
};

export default Inspiration;

const dpcKeys = {
  default: '3zkFeo3d3BHozsQB25Gwif',
  bedrooms: '1cuYo5bnt7cg20j9nYnymL',
  'dining-rooms': '1nIqFx3VJw4ZN6446cnA88',
  kitchens: 'vQgjRcaBzNhI7cBoHmLfA',
  'living-rooms': '2FuJdtzNmStNAz2eE9RYIN',
  bathrooms: '65yRMH8ADCEoT6Gr7J4ZBc',
  '3d-tours': '5vYyk8VFgTIY2rRoRkE7RH',
  exteriors: '43s6zJSJ1qzjrojtjxSNq9',
};

const getDpcKey = (category) => {
  if (category) {
    return dpcKeys[category?.toLowerCase()] || dpcKeys['default'];
  }

  return dpcKeys['default'];
};

export async function getServerSideProps({ params }) {
  let inspireDpc = null;
  let mediaViewImage = null;
  let mediaView = null;
  const [category, , mediaId] = params.category || [];
  const dpcKey = getDpcKey(category);

  if (mediaId) {
    await getInspiredContentfulMediaById(mediaId)
      .then((result) => {
        mediaView = result;
      })
      .catch((e) => {
        console.log(e);
      });

    if (!mediaView) {
      return {
        redirect: {
          destination: '/get-inspired',
          permanent: true,
        },
      };
    }
  }

  await getDynamicPageContent(dpcKey).then((result) => {
    inspireDpc = result;
  });

  return {
    props: {
      ssrInspireDpc: inspireDpc,
      ssrMediaViewImage: mediaViewImage,
      ssrMediaView: mediaView,
    },
  };
}
