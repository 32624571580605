import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

export const InspirationMediaCardStyles = styled.div`
  position: relative;

  a {
    width: 100%;
  }
  .tour,
  .share,
  .favorite {
    cursor: pointer;
  }

  .tour {
    align-items: center;
    background-color: ${Colors.primary.white.standard};
    border-radius: 2px;
    color: ${Colors.primary.black.standard};
    display: flex;
    font-weight: normal;
    justify-content: space-around;
    padding: 4px 6px;
    position: absolute;
    left: 10px;
    text-transform: uppercase;
    top: 10px;

    svg {
      height: 20px;
      margin-top: 4px;
      width: 20px;
    }
  }
  .share {
    align-items: center;
    background-color: ${Colors.primary.white.standard};
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    height: 28px;
    position: absolute;
    right: 48px;
    top: 10px;
    width: 28px;

    svg {
      margin-top: 6px;
    }
  }

  .favorite {
    align-items: center;
    background-color: ${Colors.primary.white.standard};
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
  }

  .favoriting-heart-circle {
    overflow: hidden;
  }
`;
